import * as Yup from 'yup';

function schema({ maxAmount, balanceDue }) {
  return Yup.object({
    invoiceId: Yup.string().required('An Invoice is required'),
    creditId: Yup.string().required('A Credit is required'),
    amountUsed: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .test(
        'max-amount',
        `Amount cannot exceed the credit amount of $${maxAmount}`,
        (value) => value <= maxAmount,
      )
      .test(
        'balance-due',
        `Amount cannot exceed the balance due of $${balanceDue}`,
        (value) => value <= balanceDue,
      )
      .typeError('Must be a valid Amount'),
  });
}

export default schema;
