import PropTypes from 'prop-types';

function Arches({
  toggleArch,
  upperArch,
  lowerArch,
  nestedArches = { upper: [], lower: [] },
  guideCodeId = null,
}) {
  const selectedUpper = guideCodeId
    ? nestedArches.upper.includes(guideCodeId)
    : false;
  const selectedLower = guideCodeId
    ? nestedArches.lower.includes(guideCodeId)
    : false;

  const handleClick = (arch) => {
    if (toggleArch) {
      toggleArch(arch);
    }
  };

  return (
    <>
      <g
        onClick={() => handleClick('upper')}
        role="button"
        tabIndex={0}
        aria-label="Upper Arch"
        onKeyDown={(e) => e.key === 'Enter' && handleClick('upper')}
        className="clickableArea"
      >
        <path
          pointerEvents="all"
          className={`st10 ${
            upperArch ? `activeTooth ${selectedUpper ? 'upSelected' : ''}` : ''
          }`}
          d="M404.9,2673.7h2841.6c0,0-93.3-2225.8-1420.8-2225.8S404.9,2673.7,404.9,2673.7z"
        />
      </g>
      <g
        onClick={() => handleClick('lower')}
        role="button"
        tabIndex={0}
        aria-label="Lower Arch"
        onKeyDown={(e) => e.key === 'Enter' && handleClick('lower')}
        className="clickableArea"
      >
        <path
          pointerEvents="all"
          className={`st10 ${
            lowerArch
              ? `activeTooth ${selectedLower ? 'downSelected' : ''}`
              : ''
          }`}
          d="M1807,4976.1c1327.5,0,1420.8-2225.8,1420.8-2225.8H386.2C386.2,2750.3,479.6,4976.1,1807,4976.1"
        />
      </g>
    </>
  );
}

Arches.propTypes = {
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  nestedArches: PropTypes.shape({
    upper: PropTypes.arrayOf(PropTypes.string),
    lower: PropTypes.arrayOf(PropTypes.string),
  }),
  guideCodeId: PropTypes.string,
};

export default Arches;
