import PropTypes from 'prop-types';
import './DentalChart.scss';
import {
  Arches,
  FdiNumbers,
  PalmerNumbers,
  Tooth1,
  Tooth10,
  Tooth11,
  Tooth12,
  Tooth13,
  Tooth14,
  Tooth15,
  Tooth16,
  Tooth17,
  Tooth18,
  Tooth19,
  Tooth2,
  Tooth20,
  Tooth21,
  Tooth22,
  Tooth23,
  Tooth24,
  Tooth25,
  Tooth26,
  Tooth27,
  Tooth28,
  Tooth29,
  Tooth3,
  Tooth30,
  Tooth31,
  Tooth32,
  Tooth4,
  Tooth5,
  Tooth6,
  Tooth7,
  Tooth8,
  Tooth9,
  UnsNumbers,
} from './DentalChartSvg';

function DentalChart({
  toggleArch,
  toggleTooth,
  teeth,
  designType,
  nested = false,
  disabled = false,
  nestedTeeth = {},
  nestedArches = { upper: [], lower: [] },
  guideCodeId = null,
  upperArch = false,
  lowerArch = false,
  numberingSystem,
}) {
  const commonToothProps = {
    toggleTooth,
    teeth,
    designType,
    toggleArch,
    nestedTeeth,
    guideCodeId,
  };

  const upperToothProps = {
    ...commonToothProps,
    upperArch,
  };

  const lowerToothProps = {
    ...commonToothProps,
    lowerArch,
  };

  return (
    <div
      className={`${nested ? 'chart-wrapper__nested' : 'chartWrapper'} ${
        disabled ? 'is-disabled' : ''
      }`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3627 5335.7">
        <title>
          {disabled
            ? designType.toothChart
              ? 'Tooth selection is preselected for Unite orders'
              : 'Arch selection is preselected for Unite orders'
            : designType.toothChart
            ? 'Select teeth'
            : 'Select arch/arches'}
        </title>
        <Tooth1 {...upperToothProps} />
        <Tooth2 {...upperToothProps} />
        <Tooth3 {...upperToothProps} />
        <Tooth4 {...upperToothProps} />
        <Tooth5 {...upperToothProps} />
        <Tooth6 {...upperToothProps} />
        <Tooth7 {...upperToothProps} />
        <Tooth8 {...upperToothProps} />
        <Tooth9 {...upperToothProps} />
        <Tooth10 {...upperToothProps} />
        <Tooth11 {...upperToothProps} />
        <Tooth12 {...upperToothProps} />
        <Tooth13 {...upperToothProps} />
        <Tooth14 {...upperToothProps} />
        <Tooth15 {...upperToothProps} />
        <Tooth16 {...upperToothProps} />
        <Tooth17 {...lowerToothProps} />
        <Tooth18 {...lowerToothProps} />
        <Tooth19 {...lowerToothProps} />
        <Tooth20 {...lowerToothProps} />
        <Tooth21 {...lowerToothProps} />
        <Tooth22 {...lowerToothProps} />
        <Tooth23 {...lowerToothProps} />
        <Tooth24 {...lowerToothProps} />
        <Tooth25 {...lowerToothProps} />
        <Tooth26 {...lowerToothProps} />
        <Tooth27 {...lowerToothProps} />
        <Tooth28 {...lowerToothProps} />
        <Tooth29 {...lowerToothProps} />
        <Tooth30 {...lowerToothProps} />
        <Tooth31 {...lowerToothProps} />
        <Tooth32 {...lowerToothProps} />
        {designType.archChart && (
          <Arches
            toggleArch={toggleArch}
            upperArch={upperArch}
            lowerArch={lowerArch}
            nestedArches={nestedArches}
            guideCodeId={guideCodeId}
          />
        )}
        {designType.toothChart && numberingSystem === 'uns' && <UnsNumbers />}
        {designType.toothChart && numberingSystem === 'fdi' && <FdiNumbers />}
        {designType.toothChart && numberingSystem === 'palmer' && (
          <PalmerNumbers />
        )}
      </svg>
    </div>
  );
}

DentalChart.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  toggleArch: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.object.isRequired,
  nested: PropTypes.bool,
  disabled: PropTypes.bool,
  nestedTeeth: PropTypes.object,
  nestedArches: PropTypes.shape({
    upper: PropTypes.arrayOf(PropTypes.string),
    lower: PropTypes.arrayOf(PropTypes.string),
  }),
  guideCodeId: PropTypes.string,
  upperArch: PropTypes.bool,
  lowerArch: PropTypes.bool,
  numberingSystem: PropTypes.string.isRequired,
};

export default DentalChart;
