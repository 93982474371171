import {
  TempOrdersListHeader,
  TempOrdersListQueryWrapper,
  orderActionRenderer,
} from '@fullcontour/common';
import { GET_TEMP_ORDERS_CUSTOMER } from '@fullcontour/shared-api';

function TempOrdersListCustomer() {
  const customerGridOptions = {
    columnDefs: [
      {
        field: 'name',
        headerName: 'Patient Name',
        width: 175,
        minWidth: 175,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designTypeName',
        headerName: 'Design Type',
        width: 175,
        minWidth: 175,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data?.orderItems?.[0]?.designTypeName;
        },
      },
      {
        autoHeight: true,
        suppressSpanHeaderHeight: true,
        field: 'state',
        headerName: 'Action',
        width: 175,
        minWidth: 175,
        cellRenderer: orderActionRenderer,
        valueGetter: (params) => params.data?.state || 'temp',
      },
      {
        autoHeight: true,
        field: 'createdAt',
        headerName: 'Submitted',
        type: 'dateColumn',
        suppressSpanHeaderHeight: true,
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onRowDoubleClicked: (params) => {
      window.location.href = `/orders/new?id=${params.data.id}`;
    },
    preventColumnMerge: true,
  };

  return (
    <TempOrdersListQueryWrapper
      customHeader={TempOrdersListHeader}
      query={GET_TEMP_ORDERS_CUSTOMER}
      customGridOptions={customerGridOptions}
    />
  );
}

export default TempOrdersListCustomer;
