import { OverpanelDrawerProvider } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import OverpanelDrawer from '../../OverpanelDrawer/OverpanelDrawer';
import TempOrderCaseItem from './TempOrderCaseItem';
import TempOrderCasesHeader from './TempOrderCasesHeader';

function TempOrderCases(props) {
  const { customerLocation, softwareNodes, tempOrders, showDimmer, ...rest } =
    props;

  const [open, setOpen] = useState(false);
  const [drawerBody, setDrawerBody] = useState(null);

  return (
    <OverpanelDrawerProvider
      value={{
        open,
        toggle: () => setOpen((prev) => !prev),
        body: drawerBody,
        setDrawerBody,
      }}
    >
      <>
        <div
          className="overpanelCasesContainer"
          style={{
            minHeight: 'calc(100vh - 202px)',
            maxHeight: 'calc(100vh - 202px)',
          }}
        >
          <div className="overpanelCasesGrid">
            <div className="is-flex py-4 is-align-items-center pl-3">
              Pending Cases
            </div>
            <TempOrderCasesHeader />

            <div className="columns">
              <div className="column" style={{ padding: 0, height: '100%' }}>
                <div
                  className="overpanelCasesInnerGrid"
                  style={{
                    overflowY: tempOrders.length > 0 ? 'scroll' : 'auto',
                    maxHeight: 'calc(100vh - 250px)',
                  }}
                >
                  {tempOrders.map((tempOrder, index) => (
                    <TempOrderCaseItem
                      key={tempOrder.id}
                      tempOrder={tempOrder}
                      tempOrders={tempOrders}
                      index={index}
                      customerLocation={customerLocation}
                      softwareNodes={softwareNodes}
                      {...rest}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OverpanelDrawer />
      </>
    </OverpanelDrawerProvider>
  );
}

TempOrderCases.propTypes = {
  customerLocation: PropTypes.object.isRequired,
  softwareNodes: PropTypes.array.isRequired,
  showDimmer: PropTypes.bool.isRequired,
  tempOrders: PropTypes.array.isRequired,
};

export default TempOrderCases;
