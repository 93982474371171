import PropTypes from 'prop-types';
import TempOrdersListHeaderButton from './TempOrdersListHeaderButton';

function TempOrdersListHeader({
  selectedCases,
  removeSelectedTempOrders,
  refetch,
}) {
  return (
    <div className="mb-2">
      <div className="columns is-vcentered">
        <div className="column" style={{ verticalAlign: 'middle' }}>
          <h6 className="title is-6 mb-0">Pending Cases</h6>
        </div>
        <div className="column has-text-right">
          <TempOrdersListHeaderButton
            removeSelectedTempOrders={removeSelectedTempOrders}
            selectedCases={selectedCases}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
}

TempOrdersListHeader.propTypes = {
  selectedCases: PropTypes.array.isRequired,
  removeSelectedTempOrders: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default TempOrdersListHeader;
