import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getRoot } from '../../../../utils/functions';
import { CurrentUserContext } from '../../Context';
import createNotification from '../../HelperFunctions/createNotification';

function FormWrapper({
  initialValues = () => ({}),
  schema = () => ({}),
  refreshCurrentUser = false,
  render: renderProp = () => ({}),
  ...rest
}) {
  const { action, slug, type } = useParams();
  const root = getRoot();

  const [{ errorMessages, formSuccess, successMessages }, setState] = useState({
    errorMessages: [],
    successMessages: [],
    formSuccess: false,
  });
  const { setCurrentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if (refreshCurrentUser && setCurrentUser) {
      setCurrentUser(true);
    }
  }, [refreshCurrentUser, setCurrentUser]);

  function setErrorMessages(messages) {
    setState((state) => ({ ...state, errorMessages: messages }));
    messages.map((singleMessage) => {
      createNotification.createNotification({
        type: 'error',
        message: `${singleMessage.path}: ${singleMessage.message}`,
      });
      return null;
    });
  }

  function clearErrorMessages(disableNotification) {
    const rootOrType = type || root;

    if (!disableNotification && rootOrType) {
      let text;
      if (slug) {
        text = slug;
      } else if (rootOrType.endsWith('ies')) {
        text = rootOrType.replace(/ies([^ies]*)$/, 'y$1');
      } else if (rootOrType.endsWith('s')) {
        text = rootOrType.slice(0, -1);
      } else {
        text = rootOrType;
      }
      text = text.replace(/-/g, ' ');
      const capitalizeText = text[0].toUpperCase() + text.slice(1);

      createNotification.createNotification({
        type: 'success',
        message:
          action === 'new'
            ? `${capitalizeText} was successfully created!`
            : `${capitalizeText} was successfully updated!`,
      });
    }
    setState((state) => ({ ...state, errorMessages: [], formSuccess: true }));
  }

  function getVariables() {
    if (action === 'edit' && slug) {
      return { slug };
    }
    return {};
  }

  function skipQuery() {
    return action === 'new';
  }

  return renderProp({
    errorMessages,
    successMessages,
    setErrorMessages,
    // setSuccessMessages: setSuccessMessages,
    clearErrorMessages,
    getVariables,
    skipQuery,
    formSuccess,
    initialValues,
    schema,
    ...rest,
  });
}

FormWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  refreshCurrentUser: PropTypes.bool,
};

export default memo(FormWrapper);
// FormWrapper.contextType = CurrentUserContext;
