import { useApolloClient, useReactiveVar } from '@apollo/client';
import {
  OverpanelFilesContext,
  UpdateCache,
  sendingTempOrders,
} from '@fullcontour/common';
import {
  DESTROY_TEMP_ORDER,
  GET_TEMP_ORDERS,
  GET_TEMP_ORDERS_COUNT,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TempOrderCases } from './TempOrderCases';
import { TempOrderHeader } from './TempOrderHeader';

function TempOrdersOverpanel({
  queriedTempOrders,
  customerLocation,
  softwareNodes,
  labLocationGuideCategoryGroups,
}) {
  const client = useApolloClient();
  const { toggleOverpanel } = useContext(OverpanelFilesContext);
  const [submitAll, setSubmitAll] = useState(false);
  const submittingTempOrders = useReactiveVar(sendingTempOrders);
  const [loading, setLoading] = useState(true);

  const removeTempOrder = async (tempOrderId) => {
    await client.mutate({
      mutation: DESTROY_TEMP_ORDER,
      variables: {
        input: {
          id: tempOrderId,
          clientMutationId: uuidv4(),
        },
      },
      update: (cache, { data: { destroyTempOrder } }) => {
        if (destroyTempOrder.errorMessages?.length === 0) {
          UpdateCache.filterElementFromCache({
            cache,
            id: tempOrderId,
            field: 'tempOrders',
          });
        }
      },
      refetchQueries: [
        { query: GET_TEMP_ORDERS_COUNT },
        { query: GET_TEMP_ORDERS },
      ],
    });
  };

  function removeAllTempOrders() {
    for (const tempOrder of queriedTempOrders) {
      removeTempOrder(tempOrder.id);
    }
    toggleOverpanel(false);
  }

  useEffect(() => {
    setLoading(!queriedTempOrders);
  }, [queriedTempOrders]);

  return (
    <>
      <TempOrderHeader
        setSubmitAll={() => setSubmitAll(true)}
        removeAllTempOrders={removeAllTempOrders}
        tempOrders={queriedTempOrders}
        submitAll={submitAll}
      />
      {submittingTempOrders.totalCases ? (
        <progress
          value={submittingTempOrders.casesSent}
          max={submittingTempOrders.totalCases}
          className="progress is-primary"
          style={{
            width: '100%',
          }}
        >
          Sending Pending cases...
        </progress>
      ) : null}
      <TempOrderCases
        submitAll={submitAll}
        showDimmer={!!submittingTempOrders.totalCases}
        removeTempOrder={removeTempOrder}
        tempOrders={queriedTempOrders}
        labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
        customerLocation={customerLocation}
        softwareNodes={softwareNodes}
        loading={loading}
      />
    </>
  );
}

TempOrdersOverpanel.propTypes = {
  queriedTempOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customerLocation: PropTypes.object.isRequired,
  softwareNodes: PropTypes.array.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array.isRequired,
};

export default TempOrdersOverpanel;
