const initialValues = (orderBase) => {
  return {
    businessUnitName: orderBase?.orderItems?.[0]?.businessUnit || '',
    name: orderBase?.name || '',
    instructions: orderBase?.instructions || '',
    files: [],
    orderItems: [
      {
        units: orderBase?.orderItems?.[0].units || 0,
        materialName: '',
        guideCodes: [],
        teeth: orderBase?.orderItems?.[0]?.teeth || [],
        arches: orderBase?.orderItems?.[0]?.arches || [],
        manufacturerOrganizationId: '',
        orderItemModifiersAttributes: [],
        designTypeId: orderBase?.orderItems?.[0]?.designTypeId || '',
        splitFile: false,
        printerId: '',
      },
    ], // x
    turnaroundTimeId: '',
    requiresDesignApproval: true,
    software: '',
    intOrderId: orderBase?.intOrderId || '',
    tempOrderFiles: orderBase?.files || [],
    disableName: orderBase == null,
    source: orderBase?.source || 'simple',
  };
};
export default initialValues;
