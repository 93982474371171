import { useApolloClient } from '@apollo/client';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import {
  BoxIcon,
  CheckboxField,
  FieldContainer,
  FormSection,
  ServerErrors,
  SingleDateSelect,
  withFormWrap,
} from '../../../../shared';
import { useCredit } from '../../../Credits/CreditsList';
import { create, initialValues, schema, update } from './helpers';

function BillingCreditFormModal({
  customer = null,
  type = null,
  refetch,
  billingType,
  errorMessages,
  setErrorMessages,
  clearErrorMessages,
}) {
  const client = useApolloClient();

  const [expirationDate, setExpirationDate] = useState(null);

  const { modalState, action, credit, closeModal, destroyCredit } = useCredit();

  useEffect(() => {
    credit?.expiredAt
      ? setExpirationDate(moment(credit.expiredAt))
      : setExpirationDate(moment().add(3, 'months'));
  }, [credit]);

  function handleSubmit(values, { setSubmitting }) {
    const { noExpiration, ...valuesToPersist } = values;
    if (noExpiration) {
      valuesToPersist.expiredAt = null;
    }
    const variables = {
      variables: {
        input: { input: valuesToPersist, clientMutationId: uuidv4() },
      },
    };

    if (action === 'New') {
      create({
        variables,
        client,
        refetch,
        closeModal,
        setErrorMessages,
        clearErrorMessages,
      });
    } else {
      update({ variables, client, refetch, closeModal, setErrorMessages });
    }
    setSubmitting(false);
  }

  return action ? (
    <Formik
      initialValues={initialValues({ credit, customer, type })}
      validationSchema={schema()}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit, errors, touched, values }) => (
        <form noValidate onSubmit={onSubmit}>
          <div className={`modal ${modalState ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <BoxIcon name="bxs-plus-circle is-size-3" />
                <span>
                  <h4 className="title is-5 modal-card-title mb-0 ml-4">
                    {action} {billingType}
                  </h4>
                </span>
              </header>
              <section className="modal-card-body">
                <FormSection title="Credit Info">
                  <div className="columns">
                    <div className="column">
                      <div className="control has-icons-left">
                        <FieldContainer name="amount" label="Amount" required>
                          <div
                            className={`icon is-left has-text-white ${
                              errors.amount && touched.amount
                                ? 'has-background-danger'
                                : 'has-background-success'
                            }`}
                            style={{ width: 40, borderRadius: '10% 0 0 10%' }}
                          >
                            $
                          </div>
                          <Field
                            id="amount"
                            name="amount"
                            type="number"
                            step="0.01"
                            placeholder="0.00"
                            className="input pl-6"
                          />
                        </FieldContainer>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="control">
                        <FieldContainer name="reason" required>
                          <Field
                            id="reason"
                            name="reason"
                            component="textarea"
                            rows="3"
                            required
                            placeholder="Reason"
                            className="textarea"
                          />
                        </FieldContainer>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="control">
                        <FieldContainer
                          name="expiration"
                          label="Expiration Date"
                        >
                          <div className="is-flex is-align-items-center">
                            <div className="mr-4">
                              <Field
                                id="expiredAt"
                                name="expiredAt"
                                component={SingleDateSelect}
                                onCalendarDateChange={(value) => {
                                  if (value) {
                                    values.expiredAt = value;
                                    setExpirationDate(value);
                                  }
                                }}
                                anchorDirection="left"
                                openDirection="up"
                                date={expirationDate}
                                style={{ width: '100%' }}
                                disabled={!!values.noExpiration}
                              />
                            </div>
                            <div>
                              <Field
                                name="noExpiration"
                                component={CheckboxField}
                                labelText="Don't expire?"
                                headerText=" "
                                size="mini"
                              />
                            </div>
                          </div>
                        </FieldContainer>
                      </div>
                    </div>
                  </div>
                </FormSection>
                <ServerErrors errorMessages={errorMessages} />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  className="button is-dark"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button>
                {action === 'Edit' && (
                  <button
                    className="button is-danger is-outlined"
                    type="button"
                    onClick={() => destroyCredit(credit, refetch)}
                  >
                    Delete
                  </button>
                )}
                <button className="button is-primary" type="submit">
                  {action === 'New' ? 'Create' : 'Update'}
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  ) : null;
}

BillingCreditFormModal.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
  refetch: PropTypes.func.isRequired,
  billingType: PropTypes.string.isRequired,
  errorMessages: PropTypes.array.isRequired,
  setErrorMessages: PropTypes.func.isRequired,
  clearErrorMessages: PropTypes.func.isRequired,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  BillingCreditFormModal,
);
