import { CREATE_DEBIT } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  closeModal,
  refetch,
  setErrorMessages,
  clearErrorMessages,
}) {
  try {
    const result = await client.mutate({
      mutation: CREATE_DEBIT,
      variables,
    });

    const { data } = result || {};
    const { createDebit } = data || {};

    if (createDebit?.errorMessages?.length > 0) {
      setErrorMessages(createDebit.errorMessages);
    } else {
      clearErrorMessages();
      refetch();
      closeModal();
    }
  } catch (error) {
    setErrorMessages([error.message]);
  }
}

export default create;
