import PropTypes from 'prop-types';
import { useState } from 'react';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import { useInvoice } from '../../../Invoices/InvoicesList';
import BillingInvoiceMemoFormModal from './BillingInvoiceMemoFormModal';

function BillingInvoiceMemoForm({ refetch }) {
  const { selectedItem } = useInvoice();
  const [modalOpen, setModalOpen] = useState(false);

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <>
      <BasicDropdownItem itemText="Edit Memo" clickHandler={openModal} />
      {selectedItem && (
        <BillingInvoiceMemoFormModal
          closeModal={closeModal}
          openModal={openModal}
          modalOpen={modalOpen}
          refetch={refetch}
        />
      )}
    </>
  );
}

BillingInvoiceMemoForm.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default BillingInvoiceMemoForm;
