import { gql } from '@apollo/client';

export default gql`
  query tempOrders {
    tempOrders {
      id
      name
      intOrderId
      source
      files
      softwareVersion
      createdAt
      orderItems
      location {
        id
        name
        code
        slug
      }
    }
  }
`;
