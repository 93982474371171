import { gql } from '@apollo/client';

export default gql`
  query tempOrderCustomer($id: ID!) {
    customerTempOrder(id: $id) {
      id
      name
      intOrderId
      files
      createdAt
      metaData
      source
      instructions
      softwareVersion
      orderItems
      location {
        id
        name
        code
        slug
      }
    }
  }
`;
