import { useQuery } from '@apollo/client';
import { GET_DEBITS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import DebitsListDataTable from './DebitsListDataTable';
import { DebitProvider } from './useDebit';
function DebitsListQueryWrapper({ customer = null, type = null }) {
  let variables = {};

  if (customer && type && type === 'location') {
    variables = { labLocationId: customer.id };
  }

  if (customer && type && type === 'organization') {
    variables = { labOrganizationId: customer.id };
  }

  const { error, loading, data, refetch } = useQuery(GET_DEBITS, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Debits" />;
  }

  return (
    <DebitProvider>
      <DebitsListDataTable
        debits={data.debits}
        refetch={refetch}
        customer={customer}
        type={type}
      />
    </DebitProvider>
  );
}

DebitsListQueryWrapper.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
};

export default DebitsListQueryWrapper;
