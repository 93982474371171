import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_LOC_BILLING } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { LoadingOrError } from '../../../shared';
import { Billing } from '../../Billing';

function LocationCustomerBilling() {
  const { slug } = useParams();

  const variables = slug ? { slug } : {};

  const { error, loading, data } = useQuery(GET_CUSTOMER_LOC_BILLING, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (error || loading) {
    return <LoadingOrError loading={loading} error={error} title="Billing" />;
  }

  return data?.customerLocation ? (
    <Billing customer={data.customerLocation} type="location" />
  ) : null;
}

export default LocationCustomerBilling;
