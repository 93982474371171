import { useQuery } from '@apollo/client';
import {
  CREATE_PRICE_LIST,
  GET_PRICE_LISTS_DROPDOWN_WITH_PARENT,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloneModal from '../CloneModal/CloneModal';
import Dropdown from '../Dropdown';

function PriceListDropdown({
  field: { name, value },
  form: { errors, touched, setFieldValue },
}) {
  const { t } = useTranslation('formfields');

  const [showAll, setShowAll] = useState(!!value);
  const [filteredLists, setFilteredLists] = useState([]);
  const valueRef = useRef(value);

  const { error, loading, data, refetch, networkStatus } = useQuery(
    GET_PRICE_LISTS_DROPDOWN_WITH_PARENT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const filterLists = useCallback(
    (priceLists, show) => {
      if (!priceLists) {
        return;
      }

      const sortedPriceList = [...priceLists].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      let lists;
      if (show) {
        lists = sortedPriceList.map((list) => ({
          id: list.id,
          name: list.parentListId ? `${list.name}` : `${list.name}`,
          value: list.id,
        }));
      } else {
        lists = sortedPriceList
          .filter((list) => !list.parentListId || list.id === value)
          .map((list) => ({
            id: list.id,
            name: list.parentListId ? `${list.name}` : `${list.name}`,
            value: list.id,
          }));
      }

      setFilteredLists(lists);
    },
    [value],
  );

  useEffect(() => {
    if (!data?.priceLists) {
      return;
    }

    const selectedList = data.priceLists.find((list) => list.id === value);
    const isChildList = selectedList?.parentListId;

    if (value !== valueRef.current) {
      if (isChildList) {
        setShowAll(true);
      }
      filterLists(data.priceLists, isChildList || showAll);
      valueRef.current = value;
    } else {
      filterLists(data.priceLists, showAll);
    }
  }, [value, data, filterLists, showAll]);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  const handleShowAll = (e) => {
    e.preventDefault();
    const newShowAll = !showAll;
    setShowAll(newShowAll);
    if (data?.priceLists) {
      filterLists(data.priceLists, newShowAll);
    }
  };

  return (
    <div className="field has-addons is-grouped">
      <div className="control is-expanded">
        <Dropdown
          name={name}
          label="Price List"
          error={error}
          errors={!!(errors[name] && touched[name])}
          loading={loading || networkStatus === 4}
          options={filteredLists}
          onChange={handleChange}
          placeholder={t('Select a Price List...')}
          value={value}
          required
          disabled={loading || networkStatus === 4}
        />
      </div>
      <div className="control is-align-self-flex-end">
        <button
          type="button"
          className={`button ${showAll && 'is-success'} ml-3`}
          onClick={handleShowAll}
        >
          {showAll ? 'Masters' : 'Show All'}
        </button>
      </div>
      <CloneModal
        cloneMutation={CREATE_PRICE_LIST}
        mutationDataName="createPriceList"
        dataName="priceList"
        valueToClone={value}
        options={filteredLists || []}
        cloneName="Price List"
        refetch={refetch}
        setParentFieldValue={setFieldValue}
        fieldName={name}
        loading={loading || networkStatus === 4}
        showClone={
          data?.priceLists?.length > 0 && value !== '' && value !== null
        }
        isPriceList
        showDescriptionField
      />
    </div>
  );
}

PriceListDropdown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(PriceListDropdown);
