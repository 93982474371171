import { useQuery } from '@apollo/client';
import { GET_INVOICE } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { LoadingOrError } from '../../../shared';
import { InvoiceProvider } from '../InvoicesList/useInvoice';
import InvoiceShowInner from './InvoiceShowInner';

function InvoiceShow() {
  const { hexInvoiceNumber } = useParams();

  const { error, loading, data, refetch } = useQuery(GET_INVOICE, {
    fetchPolicy: 'cache-and-network',
    variables: { hexInvoiceNumber },
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Invoice" />;
  }
  return (
    <InvoiceProvider>
      {data?.invoice ? (
        <InvoiceShowInner invoice={data.invoice} refetch={refetch} />
      ) : null}
    </InvoiceProvider>
  );
}

export default InvoiceShow;
