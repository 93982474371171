import { useApolloClient } from '@apollo/client';
import { DESTROY_TEMP_ORDER } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TempOrdersListGrid from './TempOrdersListGrid';

function TempOrdersList({
  tempOrders = [],
  loading,
  customHeader: CustomHeader,
  refetch,
  customGridOptions = {},
}) {
  const [selectedCases, setSelectedCases] = useState([]);
  const client = useApolloClient();

  const gridOptions = {
    ...customGridOptions,
    columnDefs: customGridOptions.columnDefs || [],
  };

  const onSelectionChanged = (event) => {
    setSelectedCases(event.api.getSelectedNodes());
  };

  const removeTempOrder = async (tempOrderId) => {
    await client.mutate({
      mutation: DESTROY_TEMP_ORDER,
      variables: {
        input: {
          id: tempOrderId,
          clientMutationId: uuidv4(),
        },
      },
    });
  };

  const removeSelectedTempOrders = async () => {
    for await (const selectedCase of selectedCases) {
      await removeTempOrder(selectedCase.data.id);
    }
  };

  return (
    <>
      {CustomHeader && (
        <CustomHeader
          selectedCases={selectedCases}
          removeSelectedTempOrders={removeSelectedTempOrders}
          refetch={refetch}
        />
      )}
      <TempOrdersListGrid
        gridOptions={gridOptions}
        rowData={tempOrders}
        onSelectionChanged={onSelectionChanged}
        loading={loading}
      />
    </>
  );
}

TempOrdersList.propTypes = {
  tempOrders: PropTypes.array,
  loading: PropTypes.bool,
  customHeader: PropTypes.elementType,
  refetch: PropTypes.func,
  customGridOptions: PropTypes.object,
};

export default TempOrdersList;
