import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getRoot } from '../../../../utils/functions';
import BillingChargesHeader from '../../Billing/BillingCharges/BillingChargesHeader';
import BillingGrid from '../../Billing/BillingGrid/BillingGrid';
import { useDebit } from './useDebit';
function DebitsListDataTable({
  debits,
  refetch,
  customer = null,
  type = null,
}) {
  const app = import.meta.env.VITE_APP_NAME;
  const [toggleApplied, setToggleApplied] = useState(false);
  const [rowData, setRowData] = useState([]);

  const { slug } = useParams();
  const root = getRoot();

  const { onSelectionChanged } = useDebit();

  function getUrl(params) {
    if (app === 'customer_portal') {
      return `/locations/${params.data.location.slug}`;
    }

    return `/locations/customer/${params.data.location.slug}`;
  }

  function toggleAppliedView() {
    setRowData(debits.filter((debit) => debit.applied === !toggleApplied));
    setToggleApplied(!toggleApplied);
  }

  useEffect(() => {
    setRowData(debits.filter((debit) => !debit.applied));
  }, [debits]);

  const gridOptions = {
    defaultColDef: {
      floatingFilter: true,
    },
    rowSelection: 'single',
    columnDefs: [
      {
        field: 'hexDebitNumber',
        headerName: 'Charge ID',
        minWidth: 150,
        sort: 'desc',
        filter: 'agTextColumnFilter',
        cellStyle: {
          textTransform: 'uppercase',
        },
      },
      {
        field: 'organization.name',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.navigate(
            `/organizations/customer/${params.data.organization.slug}`,
          );
        },
        hide: root !== 'billing',
      },
      {
        field: 'location.name',
        headerName: 'Location',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          if (params.data.location.slug) {
            params.context.navigate(getUrl(params));
          }

          return null;
        },
        hide:
          (app === 'admin_portal' && root !== 'billing') ||
          (app === 'customer_portal' && root !== 'organizations'),
      },
      {
        field: 'applied',
        headerName: 'Status',
        minWidth: 150,
        filter: 'agSetColumnFilter',
        valueGetter: (params) =>
          params.data.applied ? 'Applied' : 'Not Applied',
      },
      {
        field: 'reason',
        headerName: 'Memo',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'amount',
        headerName: 'Debit Amount',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
      },
      {
        field: 'businessUnit.name',
        headerName: 'Business Unit',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        hide: app !== 'admin_portal',
      },
      {
        field: 'invoice.hexInvoiceNumber',
        headerName: 'Invoice',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: {
          textTransform: 'uppercase',
          color: '#1B77C8',
          cursor: 'pointer',
        },
        onCellClicked: (params) => {
          let link;
          if (root === 'billing') {
            link = `/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          if (
            app === 'admin_portal' &&
            root === 'organizations' &&
            params.data.organization &&
            params.data.organization.slug
          ) {
            link = `/organizations/customer/${params.data.organization.slug}/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          if (
            app === 'admin_portal' &&
            root === 'locations' &&
            params.data.location &&
            params.data.location.slug
          ) {
            link = `/locations/customer/${params.data.location.slug}/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          if (app === 'customer_portal' && root === 'locations' && !slug) {
            link = `/locations/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          if (app === 'customer_portal' && root === 'locations' && slug) {
            link = `/locations/${params.data.location.slug}/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          if (app === 'customer_portal' && root === 'organizations') {
            link = `/organizations/billing/invoices/${params.data.invoice.hexInvoiceNumber}`;
          }

          params.context.navigate(link);
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        minWidth: 150,
        type: 'dateColumn',
      },
    ],
  };

  return (
    <div>
      <BillingChargesHeader
        toggleApplied={toggleApplied}
        toggleAppliedView={toggleAppliedView}
        refetch={refetch}
        customer={customer}
        type={type}
      />
      <div className="columns">
        <div className="column billingGridWrap">
          <BillingGrid
            gridOptions={gridOptions}
            rowData={rowData}
            containerHeight={550}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </div>
  );
}

DebitsListDataTable.propTypes = {
  debits: PropTypes.array.isRequired,
  refetch: PropTypes.func,
  customer: PropTypes.object,
  type: PropTypes.string,
};

export default DebitsListDataTable;
