import PropTypes from 'prop-types';

import { memo, useEffect, useMemo } from 'react';

function TurnAroundTimesDropdown(props) {
  const {
    field,
    index,
    values,
    value,
    dataSources: {
      customerLocation: {
        priceList: { priceListItems, turnaroundTimes },
      },
    },
    form: { setFieldValue },
  } = props;

  const times = useMemo(
    () =>
      turnaroundTimes.filter(({ id }) =>
        priceListItems.find(
          ({ turnaroundTimeId, designTypeId }) =>
            turnaroundTimeId === id &&
            designTypeId === values.orderItems[index].designTypeId,
        ),
      ),
    [
      values.orderItems[index].designTypeId,
      index,
      priceListItems,
      turnaroundTimes,
    ],
  );

  useEffect(() => {
    if (!value) {
      const defaultPriceListItem = priceListItems.find(
        (item) =>
          item.defaultTurnaroundTime &&
          item.designTypeId === values.orderItems[index].designTypeId,
      );

      if (defaultPriceListItem) {
        setFieldValue(field.name, defaultPriceListItem.turnaroundTimeId);
      } else if (times.length === 1) {
        setFieldValue(field.name, times[0].value);
      }
    }
  }, [
    value,
    times,
    field.name,
    setFieldValue,
    priceListItems,
    values.orderItems,
    index,
  ]);

  return times.length === 1 ? (
    <div>
      <p>
        We have automatically selected the only available turnaround time based
        on the design you are ordering. Your design should be completed by the
        time frame below:
      </p>
      <p style={{ fontWeight: 'bold', color: 'rgba(61, 172, 209, 0.9)' }}>
        {times[0].name}
      </p>
    </div>
  ) : (
    <div className="select">
      <select {...field}>
        <option value="">Please Select</option>
        {times.map(({ name, value }) => (
          <option key={name} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
TurnAroundTimesDropdown.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  dataSources: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
export default memo(TurnAroundTimesDropdown);
