import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { getRoot } from '../../../../../src/utils/functions';
import tagRenderer from '../../../shared/AgGrid/CellRenderers/tagRenderer';
import BillingGrid from '../../Billing/BillingGrid/BillingGrid';
import BillingInvoicesHeader from '../../Billing/BillingInvoices/BillingInvoicesHeader';
import { useInvoice } from '../../Invoices/InvoicesList';
function InvoicesListDataTable({ invoices, refetch, customer, type }) {
  const app = import.meta.env.VITE_APP_NAME;

  const { slug } = useParams();
  const root = getRoot();

  const { onSelectionChanged } = useInvoice();

  function getUrl(params) {
    if (app === 'customer_portal') {
      return `/locations/${params.data.location.slug}`;
    }

    return `/locations/customer/${params.data.location.slug}`;
  }

  const gridOptions = {
    defaultColDef: {
      floatingFilter: true,
    },
    rowSelection: 'single',
    columnDefs: [
      {
        headerName: 'Details',
        marryChildren: true,
        children: [
          {
            field: 'hexInvoiceNumber',
            headerName: 'Invoice ID',
            minWidth: 120,
            sort: 'desc',
            filter: 'agTextColumnFilter',
            cellStyle: {
              textTransform: 'uppercase',
              color: '#1B77C8',
              cursor: 'pointer',
            },
            onCellClicked: (params) => {
              let link;
              if (root === 'billing') {
                link = `/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              if (
                app === 'admin_portal' &&
                root === 'organizations' &&
                params.data.organization &&
                params.data.organization.slug
              ) {
                link = `/organizations/customer/${params.data.organization.slug}/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              if (
                app === 'admin_portal' &&
                root === 'locations' &&
                params.data.location &&
                params.data.location.slug
              ) {
                link = `/locations/customer/${params.data.location.slug}/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              if (app === 'customer_portal' && root === 'locations' && !slug) {
                link = `/locations/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              if (app === 'customer_portal' && root === 'locations' && slug) {
                link = `/locations/${params.data.location.slug}/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              if (app === 'customer_portal' && root === 'organizations') {
                link = `/organizations/billing/invoices/${params.data.hexInvoiceNumber}`;
              }

              params.context.navigate(link);
            },
          },
          {
            field: 'organization.name',
            headerName: 'Organization',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              params.context.navigate(
                `/organizations/customer/${params.data.organization.slug}`,
              );
            },
            hide: root !== 'billing',
          },
          {
            field: 'location.name',
            headerName: 'Location',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              if (params.data.location.slug) {
                params.context.navigate(getUrl(params));
              }

              return null;
            },
            hide:
              (app === 'admin_portal' &&
                root !== 'billing' &&
                root !== 'organizations') ||
              (app === 'customer_portal' && root !== 'organizations'),
          },
          {
            field: 'location.address.country',
            headerName: 'Country',
            minWidth: 100,
            filter: 'agTextColumnFilter',

            hide: root !== 'billing',
          },
          {
            field: 'state',
            headerName: 'Status',
            minWidth: 120,
            filter: 'agSetColumnFilter',
            cellRenderer: tagRenderer,
          },
          {
            field: 'memo',
            minWidth: 250,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        headerName: 'Dates',
        marryChildren: true,
        children: [
          {
            field: 'invoicingDate',
            headerName: 'Invoice Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'dueDate',
            headerName: 'Due Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'datePaid',
            headerName: 'Date Paid',
            columnGroupShow: 'open',
            minWidth: 160,
            type: 'dateColumn',
          },
        ],
      },
      {
        headerName: 'Amounts',
        marryChildren: true,
        children: [
          {
            field: 'totalPrice',
            headerName: 'Invoice Amount',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'totalDebits',
            headerName: 'Charges',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'totalCredits',
            headerName: 'Credits',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'appliedPayments',
            headerName: 'Payments',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'balanceDue',
            headerName: 'Balance Due',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
        ],
      },
    ],
  };

  return (
    <div>
      <div className="columns">
        <div className="column has-text-right">
          <BillingInvoicesHeader
            refetch={refetch}
            customer={customer}
            type={type}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column billingGridWrap">
          <BillingGrid
            gridOptions={gridOptions}
            rowData={invoices}
            containerHeight={550}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </div>
  );
}

InvoicesListDataTable.propTypes = {
  refetch: PropTypes.func.isRequired,
  customer: PropTypes.object,
  type: PropTypes.string,
  invoices: PropTypes.array.isRequired,
};

export default InvoicesListDataTable;
