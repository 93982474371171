import { useApolloClient } from '@apollo/client';
import { DESTROY_CREDIT, UPDATE_CREDIT_STATUS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../config/log';
import { CreateNotification } from '../../../shared';

const CreditContext = createContext();

export function CreditProvider({ children }) {
  const client = useApolloClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [action, setAction] = useState(null);
  const [credit, setCredit] = useState({});

  function closeModal() {
    setAction(null);
    setCredit({});
    setModalState(false);
  }

  function openModal(act, cred) {
    setAction(act);
    setCredit(cred);
    setModalState(true);
  }

  async function destroyCredit(cred, refetch) {
    try {
      await client.mutate({
        mutation: DESTROY_CREDIT,
        variables: {
          input: {
            id: cred.id,
            clientMutationId: uuidv4(),
          },
        },
      });

      closeModal();
      refetch();
    } catch (err) {
      Log.error(err);
    }
  }

  async function statusUpdate(statusAction, cred, refetch) {
    try {
      await client.mutate({
        mutation: UPDATE_CREDIT_STATUS,
        variables: {
          input: {
            input: {
              id: cred.id,
              statusAction,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      CreateNotification.createNotification({
        type: 'success',
        message: `Status was changed to "${statusAction}"`,
      });
      refetch();
    } catch (err) {
      Log.error(err);
    }
  }

  function onSelectionChanged(event) {
    const selectedItems = event.api.getSelectedNodes();
    const selected = selectedItems.length > 0 ? selectedItems[0].data : null;

    setSelectedItem(selected);
  }

  return (
    <CreditContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        action,
        credit,
        closeModal,
        openModal,
        destroyCredit,
        statusUpdate,
        onSelectionChanged,
        modalState,
      }}
    >
      {children}
    </CreditContext.Provider>
  );
}

CreditProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useCredit() {
  return useContext(CreditContext);
}
