import { useApolloClient } from '@apollo/client';
import { DESTROY_DEBIT, UPDATE_DEBIT_STATUS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../config/log';
import { CreateNotification } from '../../../shared';

const DebitContext = createContext();

export function DebitProvider({ children }) {
  const client = useApolloClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [action, setAction] = useState(null);
  const [debit, setDebit] = useState({});

  function closeModal() {
    setAction(null);
    setDebit({});
    setModalState(false);
  }

  function openModal(act, deb) {
    setAction(act);
    setDebit(deb);
    setModalState(true);
  }

  async function destroyDebit(deb, refetch) {
    try {
      await client.mutate({
        mutation: DESTROY_DEBIT,
        variables: {
          input: {
            id: deb.id,
            clientMutationId: uuidv4(),
          },
        },
      });

      closeModal();
      refetch();
    } catch (err) {
      Log.error(err);
    }
  }

  async function statusUpdate(statusAction, deb, refetch) {
    try {
      await client.mutate({
        mutation: UPDATE_DEBIT_STATUS,
        variables: {
          input: {
            input: {
              id: deb.id,
              statusAction,
            },
            clientMutationId: uuidv4(),
          },
        },
      });

      CreateNotification.createNotification({
        type: 'success',
        message: `Status was changed to "${statusAction}"`,
      });

      refetch();
    } catch (err) {
      Log.error(err);
    }
  }

  function onSelectionChanged(event) {
    const selectedItems = event.api.getSelectedNodes();
    const selected = selectedItems.length > 0 ? selectedItems[0].data : null;

    setSelectedItem(selected);
  }

  return (
    <DebitContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        action,
        debit,
        closeModal,
        openModal,
        destroyDebit,
        statusUpdate,
        onSelectionChanged,
        modalState,
      }}
    >
      {children}
    </DebitContext.Provider>
  );
}

DebitProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useDebit() {
  return useContext(DebitContext);
}
