import * as Yup from 'yup';

function validateFiles(value, designTypes, parent) {
  const selectedDesignType = designTypes[parent.businessUnitName]?.find(
    ({ id }) => id === parent.orderItems[0].designTypeId,
  );
  if (!selectedDesignType) {
    return false;
  }

  const folders = selectedDesignType.designTypeCaseFolders.map((dCF) => ({
    ...dCF,
    ...selectedDesignType.caseFolders.find((cF) => cF.id === dCF.caseFolderId),
  }));

  const errors = folders.reduce(
    (acc, { folderName, required, caseFolderId }) => {
      const hasRequiredFile = value?.some(
        (file) => file.caseFolderId === caseFolderId,
      );
      if (!hasRequiredFile && required) {
        acc.push({ [caseFolderId]: `${folderName} is required.` });
      }
      return acc;
    },
    [],
  );

  return errors.length > 0 ? errors : true;
}

function validateTeethOrArches(designTypes, parent, context) {
  const selectedDesignType = designTypes[context.businessUnitName]?.find(
    ({ id }) => id === parent.designTypeId,
  );

  if (selectedDesignType) {
    const { toothChart, archChart } = selectedDesignType;
    if (toothChart && parent.teeth.length === 0) {
      return 'Please select at least one tooth';
    }
    if (archChart && parent.arches.length === 0) {
      return 'Please select at least one arch';
    }
    return true;
  }
  return 'Required';
}

function schema({ designTypes }) {
  return Yup.object({
    businessUnitName: Yup.string(),
    name: Yup.string(),
    instructions: Yup.string(),
    files: Yup.array().test(
      'check-required-files',
      'Required',
      function (value) {
        const result = validateFiles(value, designTypes, this.parent);
        if (result === true) {
          return true;
        }
        return this.createError({ message: result, path: 'files' });
      },
    ),
    orderItems: Yup.array().of(
      Yup.object().shape({
        units: Yup.number(),
        materialName: Yup.string(),
        guideCodes: Yup.array().of(Yup.object()),
        teeth: Yup.array().of(Yup.number()),
        arches: Yup.array().of(Yup.string()),
        manufacturerOrganizationId: Yup.string(),
        orderItemModifiersAttributes: Yup.array(),
        designTypeId: Yup.string(),
        splitFile: Yup.boolean(),
        printerId: Yup.string(),
        teethChart: Yup.string().test(
          'checks-teeths-or-arches',
          'Please select at least 1 unit',
          function () {
            const error = validateTeethOrArches(
              designTypes,
              this.parent,
              this.options.context,
            );
            return error === true || this.createError({ message: error });
          },
        ),
      }),
    ),
    turnaroundTimeId: Yup.string(),
    requiresDesignApproval: Yup.boolean(),
    intOrderId: Yup.string(),
    software: Yup.string(),
  });
}

export default schema;
