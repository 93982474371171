import { useQuery } from '@apollo/client';
import { GET_CREDIT_CARDS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import BillingCreditCards from '../BillingCreditCards/BillingCreditCards';

function BillingDetailsCreditCards({ customer, type }) {
  const variables = {
    labLocationId: type === 'location' ? customer.id : null,
    labOrganizationId:
      type === 'location' ? customer.organization.id : customer.id,
  };

  const { error, loading, data, refetch } = useQuery(GET_CREDIT_CARDS, {
    variables,
  });

  if (loading || error) {
    return (
      <LoadingOrError loading={loading} error={error} title="Payment Methods" />
    );
  }

  const { creditCards } = data;

  return data?.creditCards ? (
    <BillingCreditCards
      creditCards={creditCards}
      type={type}
      refetch={refetch}
      customer={customer}
    />
  ) : null;
}

BillingDetailsCreditCards.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default BillingDetailsCreditCards;
