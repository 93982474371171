import PropTypes from 'prop-types';

function priceListRenderer({ data }) {
  const priceList = data?.attributes?.priceList || data?.priceList || null;
  const priceListIsCustom =
    data?.attributes?.priceListIsCustom || data?.priceListIsCustom;

  if (!priceList?.name) {
    return null;
  }

  return (
    <span>
      {priceList.name}
      {priceListIsCustom && (
        <div
          className="tag is-warning"
          style={{
            marginLeft: '5px',
            verticalAlign: 'top',
            marginTop: '4%',
          }}
        >
          custom
        </div>
      )}
    </span>
  );
}

priceListRenderer.propTypes = {
  data: PropTypes.shape({
    attributes: PropTypes.shape({
      priceList: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      priceListIsCustom: PropTypes.bool,
    }),
    priceList: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    priceListIsCustom: PropTypes.bool,
  }),
};

export default priceListRenderer;
