import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tabs from '../../shared/Components/NewTabs';
import { CreditsListQueryWrapper } from '../Credits/CreditsList';
import { DebitsListQueryWrapper } from '../Debits/DebitsList';
import { InvoicesListQueryWrapper } from '../Invoices/InvoicesList';
import './Billing.css';
import { BillingDetails } from './BillingDetails';

function Billing({ customer, type }) {
  const { t } = useTranslation('billing');

  return (
    <div className="is-flex is-flex-direction-column is-justify-content-space-between">
      <BillingDetails customer={customer} type={type} />
      <Tabs>
        <div id={t('Invoices')} style={{ height: '100%' }}>
          <InvoicesListQueryWrapper customer={customer} type={type} />
        </div>
        <div id={t('Credits')} style={{ height: '100%' }}>
          <CreditsListQueryWrapper customer={customer} type={type} />
        </div>
        <div id={t('Charges')} style={{ height: '100%' }}>
          <DebitsListQueryWrapper customer={customer} type={type} />
        </div>
      </Tabs>
    </div>
  );
}

Billing.propTypes = {
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default Billing;
