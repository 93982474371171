import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router';
import '../../../components/shared/AgGrid/ag-grid-css-overrides.css';
import '../../../components/shared/AgGrid/ag-theme-balham-overrides.css';
import { gridDateComparator, timestampFormatter } from '../../../transforms';
import { AgGridLoader } from '../../shared';

function TempOrdersListGrid({
  gridOptions,
  rowData,
  onSelectionChanged,
  containerHeight = 'calc(100vh - 190px - 3em)',
}) {
  const navigate = useNavigate();
  const { components, columnDefs, ...gridRest } = gridOptions;

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onColumnVisible = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onDisplayedColumnsChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const modules = useMemo(
    () => [
      ClientSideRowModelModule,
      InfiniteRowModelModule,
      CsvExportModule,
      SparklinesModule,
      ClipboardModule,
      ColumnsToolPanelModule,
      ExcelExportModule,
      FiltersToolPanelModule,
      MasterDetailModule,
      MenuModule,
      RangeSelectionModule,
      RichSelectModule,
      RowGroupingModule,
      SetFilterModule,
      MultiFilterModule,
      AdvancedFilterModule,
      SideBarModule,
      StatusBarModule,
      ViewportRowModelModule,
    ],
    [],
  );

  return (
    <div
      className="ag-theme-balham mainGridContainer ordersGridContainer"
      style={{
        height: containerHeight,
      }}
    >
      <AgGridReact
        gridOptions={gridRest}
        headerHeight={40}
        rowHeight={40}
        rowData={rowData}
        animateRows
        maintainColumnOrder
        columnDefs={[
          {
            field: '',
            width: 40,
            minWidth: 40,
            filter: false,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressColumnsToolPanel: true,
            lockPosition: true,
            resizable: false,
            suppressSizeToFit: true,
            suppressMovable: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            pinned: 'left',
          },
          ...columnDefs,
        ]}
        context={{
          navigate,
        }}
        modules={modules}
        components={{
          customLoadingOverlay: AgGridLoader,
          ...components,
        }}
        loadingOverlayComponent="customLoadingOverlay"
        floatingFiltersHeight={46}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        onColumnVisible={onColumnVisible}
        onDisplayedColumnsChanged={onDisplayedColumnsChanged}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        defaultColDef={{
          resizable: true,
          editable: false,
          sortable: true,
          filterParams: { newRowsAction: 'keep' },
          floatingFilter: true,
        }}
        columnTypes={{
          dateColumn: {
            width: 185,
            minWidth: 185,
            filter: 'agDateColumnFilter',
            filterParams: {
              newRowsAction: 'keep',
              comparator: (filterLocalDateAtMidnight, cellValue) =>
                gridDateComparator(filterLocalDateAtMidnight, cellValue),
            },
            valueFormatter: (params) => {
              if (params.value) {
                return timestampFormatter(params.value);
              }
              return params.value;
            },
          },
        }}
      />
    </div>
  );
}

TempOrdersListGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array,
  onSelectionChanged: PropTypes.func.isRequired,
  containerHeight: PropTypes.string,
  loading: PropTypes.bool,
};

export default memo(TempOrdersListGrid);
