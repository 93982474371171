import Proptypes from 'prop-types';
import { memo, useEffect, useRef } from 'react';

function CaseNameInput({ form, field }) {
  const {
    currentStepIndex: { _sectionIndex },
  } = form;
  const inputRef = useRef(null);

  useEffect(() => {
    if (field.value !== '') {
      inputRef.current.focus();
      inputRef.current.blur();
    }
  }, []);

  return (
    <>
      <input
        maxLength={100}
        disabled={form.initialValues.disableName && _sectionIndex > 1}
        className="input"
        {...field}
        ref={inputRef}
      />
      {field.value?.length >= 100 && _sectionIndex <= 1 && (
        <small className="has-text-danger">
          Maximum characters of 100 reached
        </small>
      )}
      {form.initialValues.disableName && _sectionIndex > 1 && (
        <small className="has-text-danger">
          Case name can no longer be changed. To change the name please reload
          the form.
        </small>
      )}
    </>
  );
}

CaseNameInput.propTypes = {
  field: Proptypes.object.isRequired,
  form: Proptypes.object.isRequired,
};

export default memo(CaseNameInput);
