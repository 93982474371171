import { useQuery } from '@apollo/client';
import { Billing, LoadingOrError } from '@fullcontour/common';
import { GET_CUSTOMER_ORG_BILLING } from '@fullcontour/shared-api';
import { useParams } from 'react-router';

function OrganizationCustomerBilling() {
  const { slug } = useParams();

  const variables = slug ? { slug } : {};

  const { error, loading, data } = useQuery(GET_CUSTOMER_ORG_BILLING, {
    variables,
  });

  if (error || loading) {
    return <LoadingOrError loading={loading} error={error} title="Billing" />;
  }

  return data?.customerOrganization ? (
    <Billing customer={data.customerOrganization} type="organization" />
  ) : null;
}

export default OrganizationCustomerBilling;
