import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import ChartSelectionPopup from './ChartSelectionPopup/ChartSelectionPopup';

function ChartSelection(props) {
  const {
    index,
    dataSources: {
      customerLocation: { numberingSystem },
    },
    form: { setFieldValue },
    values: { businessUnitName, orderItems },
    data: { designTypes },
    setShouldShow,
    disabled = false,
  } = props;

  const selectedDesignType =
    businessUnitName && designTypes[businessUnitName]
      ? designTypes[businessUnitName].find(
          ({ id }) => id === orderItems[index].designTypeId,
        )
      : null;

  useEffect(() => {
    if (
      selectedDesignType !== undefined &&
      !selectedDesignType?.toothChart &&
      !selectedDesignType?.archChart
    ) {
      setShouldShow(false);
    }
  }, [selectedDesignType, setShouldShow]);

  useEffect(() => {
    if (selectedDesignType) {
      calculateUnits({});
    }
  }, [selectedDesignType]);

  const calculateUnitsByTeeth = (teeth) => {
    if (!teeth || teeth.length === 0) {
      return 1;
    }
    return teeth.length;
  };

  const calculateUnitsByArches = (arches) => {
    if (!arches || arches.length === 0) {
      return 1;
    }
    return arches.length;
  };

  const calculateUnitsByToothChart = (teeth) => {
    if (!teeth || teeth.length === 0) {
      return 1;
    }

    let isToothFromLowerArch = false;
    let isToothFromUpperArch = false;

    for (const tooth of teeth) {
      if (tooth < 17) {
        isToothFromUpperArch = true;
      } else {
        isToothFromLowerArch = true;
      }
    }

    return isToothFromLowerArch + isToothFromUpperArch || 1;
  };

  const calculateUnits = ({ newTeeth = null, newArches = null }) => {
    if (selectedDesignType?.billByTooth) {
      return calculateUnitsByTeeth(newTeeth ?? orderItems[index].teeth);
    }

    if (selectedDesignType?.billByArch) {
      if (selectedDesignType.toothChart && newTeeth) {
        return calculateUnitsByToothChart(newTeeth);
      }
      return calculateUnitsByArches(newArches || orderItems[index].arches);
    }

    return 1;
  };

  const toggleArch = (arch) => {
    if (disabled) {
      return;
    }

    let newArches;

    const foundArchIndex = orderItems[index].arches.findIndex(
      (arche) => arche === arch,
    );

    if (foundArchIndex === -1) {
      newArches = [...orderItems[index].arches, arch];
    }

    if (foundArchIndex !== -1) {
      newArches = orderItems[index].arches.filter((arche) => arche !== arch);
    }

    setFieldValue(`orderItems[${index}].arches`, newArches);
    calculateUnits({ newArches });
  };

  const toggleTooth = (toothNumber) => {
    if (disabled) {
      return;
    }

    let newTeeth;

    const foundToothIndex = orderItems[index].teeth.findIndex(
      (tooth) => Number(tooth) === toothNumber,
    );

    if (foundToothIndex === -1) {
      newTeeth = [...orderItems[index].teeth, toothNumber];
    }

    if (foundToothIndex !== -1) {
      newTeeth = orderItems[index].teeth.filter(
        (tooth) => tooth !== toothNumber,
      );
    }

    setFieldValue(`orderItems[${index}].teeth`, newTeeth);
    calculateUnits({ newTeeth });
  };

  return selectedDesignType &&
    (selectedDesignType.toothChart || selectedDesignType.archChart) ? (
    <div>
      <ChartSelectionPopup
        disabled={disabled}
        numberingSystem={numberingSystem}
        toggleArch={toggleArch}
        toggleTooth={toggleTooth}
        orderItem={orderItems[index]}
        designType={selectedDesignType}
      />
    </div>
  ) : null;
}

ChartSelection.propTypes = {
  index: PropTypes.number.isRequired,
  dataSources: PropTypes.shape({
    customerLocation: PropTypes.shape({
      numberingSystem: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    businessUnitName: PropTypes.string.isRequired,
    orderItems: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    designTypes: PropTypes.object.isRequired,
  }).isRequired,
  setShouldShow: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default memo(ChartSelection);
