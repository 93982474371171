import { useQuery } from '@apollo/client';
import { LoadingOrError } from '@fullcontour/common';
import { GET_TEMP_ORDERS_CUSTOMER } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import TempOrdersList from './TempOrdersList';

function TempOrdersListQueryWrapper({
  query,
  customHeader,
  customGridOptions,
}) {
  const { error, loading, data, refetch } = useQuery(
    query || GET_TEMP_ORDERS_CUSTOMER,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (loading || error) {
    return (
      <div className="ag-theme-balham mainGridContainer ordersGridContainer">
        <LoadingOrError
          error={error}
          loading={loading}
          title="Pending Orders"
        />
      </div>
    );
  }

  return (
    <TempOrdersList
      tempOrders={data?.tempOrders || []}
      refetch={refetch}
      loading={loading}
      customHeader={customHeader}
      customGridOptions={customGridOptions}
    />
  );
}

TempOrdersListQueryWrapper.propTypes = {
  query: PropTypes.object,
  customHeader: PropTypes.elementType,
  customGridOptions: PropTypes.object,
};

export default TempOrdersListQueryWrapper;
