import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  BaseLoader,
  OverpanelDrawerProvider,
  OverpanelFilesContext,
  guidedFlow,
  pendingCasesAmount,
  sendingOrders,
  sendingTempOrders,
} from '@fullcontour/common';
import {
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
  GET_TEMP_ORDERS_CUSTOMER,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import OverpanelDrawer from '../OverpanelDrawer/OverpanelDrawer';
import OverpanelInformation from '../OverpanelInformation/OverpanelInformation';
import TempOrdersOverpanel from '../TempOrders/TempOrdersOverpanel';
import OverpanelCaseItem from './OverpanelCaseItem';
import OverpanelCasesHeader from './OverpanelCasesHeader';
import { BrowseFiles, ProgressInfo } from './components';

const progressClosePanelStylesBox = {
  position: 'absolute',
  width: '50%',
  left: '50%',
  transform: 'translateX(-66%)',
  display: 'flex',
  flexDirection: 'column',
};

function OverpanelCases(props) {
  const {
    data: { customerLocation, softwareNodes },
  } = props;
  const { toggleOverpanel } = useContext(OverpanelFilesContext);

  const isGuidedFlow = useReactiveVar(guidedFlow);
  const submittingOrders = useReactiveVar(sendingOrders);
  const submittingTempOrders = useReactiveVar(sendingTempOrders);

  const [open, setOpen] = useState(false);
  const [drawerBody, setDrawerBody] = useState(null);

  const inputRef = useRef(null);
  const { pushFiles, overpanelOpen, files } = useContext(OverpanelFilesContext);
  const [fetchTempOrders, { data, loading }] = useLazyQuery(
    GET_TEMP_ORDERS_CUSTOMER,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted({ tempOrders }) {
        if (tempOrders.length > 0) {
          toggleOverpanel('pending');
        }
      },
    },
  );

  const { data: dataL, refetch } = useQuery(
    GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        fetchTempOrders();
      },
    },
  );

  useEffect(() => {
    if (data?.tempOrders) {
      pendingCasesAmount(data.tempOrders.length);
    }
  }, [data]);

  function onChangeHandler({ target: { files } }) {
    const acceptedFiles = [...files].map((file) => ({
      file,
      isChecked: false,
      id: uuidv4(),
    }));
    pushFiles(acceptedFiles);
  }

  const renderLoader = (title) => <BaseLoader loading title={title} />;

  const renderProgress = (submittingOrders, overpanelOpen) =>
    submittingOrders.totalCases && overpanelOpen ? (
      <progress
        className="progress is-primary is-small"
        value={submittingOrders.casesSent}
        max={submittingOrders.totalCases}
        style={{ width: '100%' }}
      >
        Sending cases <BaseLoader loading style={{ marginLeft: '5px' }} />
      </progress>
    ) : null;

  const renderOverpanelCasesContent = ({
    files,
    customerLocation,
    softwareNodes,
    dataL,
    inputRef,
    onChangeHandler,
    isGuidedFlow,
  }) => (
    <div style={{ width: '100%' }}>
      <div
        className="overpanelCasesInnerGrid"
        style={{
          overflowY: files.length > 0 ? 'scroll' : 'auto',
        }}
      >
        <TransitionGroup className="caseTranistion" component={null}>
          {files.map((fileContainer, index) => (
            <CSSTransition
              key={fileContainer.id}
              timeout={500}
              classNames="fade"
              component={null}
            >
              <OverpanelCaseItem
                file={fileContainer.file}
                fileIndex={index}
                customerLocation={customerLocation}
                softwareNodes={softwareNodes}
                labLocationGuideCategoryGroups={
                  dataL?.labLocationGuideCategoryGroups || []
                }
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        <BrowseFiles
          inputRef={inputRef}
          onChangeHandler={onChangeHandler}
          files={files}
          isGuidedFlow={isGuidedFlow}
        />
      </div>
    </div>
  );

  const overpanelBody = () => {
    if (overpanelOpen === 'pending') {
      return loading && !data ? (
        renderLoader('Pending cases')
      ) : (
        <TempOrdersOverpanel
          queriedTempOrders={data?.tempOrders}
          customerLocation={data?.customerLocation}
          softwareNodes={data?.softwareNodes}
          labLocationGuideCategoryGroups={
            dataL?.labLocationGuideCategoryGroups || []
          }
        />
      );
    }

    const infoPanelVisible = files.length === 0;

    return (
      <div
        className="overpanelCasesContainer"
        style={{ display: files.length > 0 ? 'block' : 'flex' }}
      >
        <div className="overpanelCasesGrid">
          {renderProgress(submittingOrders, overpanelOpen)}

          {!overpanelOpen && (
            <ProgressInfo
              submittingOrders={submittingOrders}
              submittingTempOrders={submittingTempOrders}
              progressClosePanelStylesBox={progressClosePanelStylesBox}
              overpanelOpen={overpanelOpen}
            />
          )}

          {files.length > 0 && <OverpanelCasesHeader />}

          <div className={infoPanelVisible ? 'is-flex' : ''}>
            <OverpanelInformation isVisible={infoPanelVisible} />
            {renderOverpanelCasesContent({
              files,
              customerLocation,
              softwareNodes,
              dataL,
              inputRef,
              onChangeHandler,
              isGuidedFlow,
            })}
          </div>
        </div>

        <OverpanelDrawer />
      </div>
    );
  };

  return (
    <OverpanelDrawerProvider
      value={{
        open,
        toggle: () => setOpen((prev) => !prev),
        body: drawerBody,
        setDrawerBody,
        refetch,
      }}
    >
      {overpanelBody()}
    </OverpanelDrawerProvider>
  );
}

OverpanelCases.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OverpanelCases;
