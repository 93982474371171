import { UPDATE_DEBIT } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  closeModal,
  refetch,
  setErrorMessages,
  clearErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_DEBIT,
    variables,
  });
  const { updateDebit } = result.data;
  if (updateDebit.errorMessages.length > 0) {
    setErrorMessages(updateDebit.errorMessages);
  } else {
    clearErrorMessages();
    refetch();
    closeModal();
  }
}

export default update;
