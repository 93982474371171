import { useQuery } from '@apollo/client';
import { GET_INVOICES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { LoadingOrError } from '../../../shared';
import InvoicesListDataTable from './InvoicesListDataTable';
import { InvoiceProvider } from './useInvoice';

function InvoicesListQueryWrapper({ customer = null, type = null }) {
  let variables = {};

  if (customer && type && type === 'location') {
    variables = { labLocationId: customer.id };
  }

  if (customer && type && type === 'organization') {
    variables = { labOrganizationId: customer.id };
  }

  const { error, loading, data, refetch } = useQuery(GET_INVOICES, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Invoices" />;
  }

  return (
    <InvoiceProvider>
      <InvoicesListDataTable
        invoices={data.invoices}
        refetch={refetch}
        customer={customer}
        type={type}
      />
    </InvoiceProvider>
  );
}

InvoicesListQueryWrapper.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
};

export default InvoicesListQueryWrapper;
