import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function TempOrdersListHeaderButton(props) {
  const { selectedCases, removeSelectedTempOrders, refetch } = props;

  const { t } = useTranslation('orderactions');
  const [confirmState, setConfirmState] = useState({ open: false });

  const show = () => setConfirmState({ open: true });
  const handleCancel = () => setConfirmState({ open: false });

  const handleConfirmDelete = async () => {
    await removeSelectedTempOrders();
    await refetch();
    handleCancel();
  };

  const buttonName = `Delete ${
    selectedCases.length > 1 ? selectedCases.length : ''
  } Case${selectedCases.length === 1 ? '' : 's'}`;

  return (
    <>
      <button
        type="button"
        className={`button is-small ${
          selectedCases.length > 0 ? 'is-danger' : 'is-danger is-light'
        }`}
        disabled={selectedCases.length === 0}
        onClick={show}
      >
        {selectedCases.length > 0 ? buttonName : t('Select Cases To Delete')}
      </button>

      <div className={`modal ${confirmState.open ? 'is-active' : ''}`}>
        <div className="modal-background" />
        <div
          className="modal-content has-text-left"
          style={{
            backgroundColor: '#fff',
            padding: '1em',
            borderRadius: 12,
          }}
        >
          <div className="block">
            <h3 className="title is-3">{t('Delete Pending Cases?')}</h3>
            <p>{t('Are you sure you want to delete pending cases?')}</p>
          </div>
          <div className="block">
            <div className="buttons is-pulled-right">
              <button
                type="button"
                className="button is-light is-danger is-outlined"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button is-primary"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={handleCancel}
        />
      </div>
    </>
  );
}

TempOrdersListHeaderButton.propTypes = {
  selectedCases: PropTypes.array.isRequired,
  removeSelectedTempOrders: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default memo(TempOrdersListHeaderButton);
