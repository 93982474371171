import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BillingContactFormModal from './BillingContactFormModal';

function BillingContactForm({
  modalOpen,
  openModal,
  closeModal,
  action = null,
  customer,
  type,
  contact,
  refetch,
  destroyContact,
}) {
  const { t } = useTranslation('billing');
  return (
    <>
      <button
        className="button is-small is-primary mt-5"
        type="button"
        onClick={() => openModal('New')}
      >
        <span className="icon is-small">
          <i className="bx bx-plus-medical" />
        </span>
        <span>{t('New Billing Contact')}</span>
      </button>

      <BillingContactFormModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        customer={customer}
        type={type}
        action={action}
        contact={contact}
        refetch={refetch}
        destroyContact={destroyContact}
      />
    </>
  );
}

BillingContactForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  contact: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  destroyContact: PropTypes.func.isRequired,
};

export default BillingContactForm;
