import PropTypes from 'prop-types';
import { useState } from 'react';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import { useInvoice } from '../../../Invoices/InvoicesList';
import BillingInvoicePostPaymentFormModal from './BillingInvoicePostPaymentFormModal';

function BillingInvoicePostPaymentForm({ refetch }) {
  const [modalOpen, setModalOpen] = useState(false);

  const { selectedItem } = useInvoice();

  return selectedItem?.state !== 'paid' ? (
    <>
      <BasicDropdownItem
        itemText="Post Payment"
        clickHandler={() => setModalOpen(true)}
        disabled={selectedItem && selectedItem.state === 'paid'}
      />
      {selectedItem && (
        <BillingInvoicePostPaymentFormModal
          refetch={refetch}
          modalOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
        />
      )}
    </>
  ) : null;
}

BillingInvoicePostPaymentForm.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default BillingInvoicePostPaymentForm;
