import PropTypes from 'prop-types';
import { getRoot } from '../../../../../utils/functions';
import { BoxIcon } from '../../../../shared';
import { useDebit } from '../../../Debits/DebitsList/useDebit';
import BillingChargeFormModal from './BillingChargeFormModal';
function BillingChargeForm({
  customer = null,
  type = null,
  billingType = 'Charge',
  refetch,
}) {
  const root = getRoot();

  const { openModal } = useDebit();

  return (
    <>
      {root !== 'billing' && (
        <button
          type="button"
          className="button mr-3"
          onClick={() => openModal('New')}
        >
          <BoxIcon name="bx-plus-medical" />
          <span>New {billingType}</span>
        </button>
      )}

      <BillingChargeFormModal
        customer={customer}
        type={type}
        billingType={billingType}
        refetch={refetch}
      />
    </>
  );
}

BillingChargeForm.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
  billingType: PropTypes.string,
  refetch: PropTypes.func.isRequired,
};

export default BillingChargeForm;
