import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CaseItemActions({ index, removeOrderItem, isParsed }) {
  const { t } = useTranslation('orders');

  return !isParsed ? (
    <div className="is-align-self-flex-start" style={{ marginTop: 24 }}>
      <button
        type="button"
        className={`nonOpaqueButton button has-tooltip-arrow mr-3 py-4 ${
          index && 'is-danger is-outlined'
        }`}
        disabled={index === 0}
        style={{ ...(index === 0 ? { color: '#ccc' } : {}) }}
        onClick={() => removeOrderItem(index)}
        data-tooltip={
          index === 0
            ? 'This item cannont be removed since all cases need at least 1 design type'
            : 'Remove this item from the case'
        }
      >
        <span className="icon">
          <i className="bx bx-trash" />
        </span>
      </button>
    </div>
  ) : (
    <div className="is-align-self-flex-start" style={{ marginTop: 24 }}>
      <button
        type="button"
        className="nonOpaqueButton button has-tooltip-arrow is-disabled mr-3 py-4"
        disabled={true}
        style={{ color: '#ccc' }}
        onClick={() => removeOrderItem(index)}
        data-tooltip={t(
          'Individual items cannot be removed from Dental System cases',
        )}
      >
        <span className="icon">
          <i className="bx bx-trash" />
        </span>
      </button>
    </div>
  );
}

CaseItemActions.propTypes = {
  index: PropTypes.number.isRequired,
  removeOrderItem: PropTypes.func.isRequired,
  isParsed: PropTypes.bool.isRequired,
};

export default CaseItemActions;
