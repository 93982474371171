import { useQuery } from '@apollo/client';
import { GET_SHOW_PENDING } from '@fullcontour/shared-api';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BoxIcon from '../../../../Icons/BoxIcon';
import PendingLabelQuery from '../PendingLabel/PendingLabelQuery';

function PendingMenuItem() {
  const { t } = useTranslation('navigation');
  const app = import.meta.env.VITE_APP_NAME;

  const { error, loading, data } = useQuery(GET_SHOW_PENDING, {
    fetchPolicy: 'cache-and-network',
    skip: app === 'admin_portal',
  });

  if (loading || error) {
    return null;
  }

  return app === 'admin_portal' ||
    (app === 'customer_portal' && data && data.showPending) ? (
    <li>
      <NavLink
        to="/orders/pending"
        className="allOrdersItem"
        // activeClassName="is-active"
      >
        <BoxIcon name="bx-cloud-download bx-xs" />
        <span className="orderItemTitle">{t('Pending Cases')}</span>
        <PendingLabelQuery />
      </NavLink>
    </li>
  ) : null;
}

export default PendingMenuItem;
