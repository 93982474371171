import { useApolloClient } from '@apollo/client';
import { GET_BUSINESS_UNITS_DROPDOWN } from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import {
  BoxIcon,
  FieldContainer,
  SelectField,
  ServerErrors,
  withFormWrap,
} from '../../../../shared';
import { useDebit } from '../../../Debits/DebitsList/useDebit';
import { create, initialValues, schema, update } from './helpers';

function BillingChargeFormModal({
  customer = null,
  type = null,
  refetch,
  billingType,
  errorMessages,
  setErrorMessages,
  clearErrorMessages,
}) {
  const client = useApolloClient();

  const { closeModal, action, debit, modalState, destroyDebit } = useDebit();

  function resetAndCloseModal(resetForm) {
    resetForm();
    closeModal();
  }

  function handleSubmit(values, { setSubmitting }) {
    const variables = {
      variables: {
        input: { input: values, clientMutationId: uuidv4() },
      },
    };

    if (action === 'New') {
      variables.variables.input.input.id = undefined;
      create({
        variables,
        client,
        closeModal,
        refetch,
        setErrorMessages,
        clearErrorMessages,
      });
    } else {
      update({
        variables,
        client,
        closeModal,
        refetch,
        setErrorMessages,
        clearErrorMessages,
      });
    }
    setSubmitting(false);
  }

  return action ? (
    <Formik
      initialValues={initialValues({ customer, type, debit })}
      validationSchema={schema({ action })}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit, resetForm, errors, touched }) => (
        <form noValidate onSubmit={onSubmit}>
          <div className={`modal ${modalState ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <BoxIcon name="bxs-plus-circle is-size-3" />
                <span>
                  <h4 className="title is-5 modal-card-title mb-0 ml-4">
                    {action} {billingType}
                  </h4>
                </span>
              </header>
              <section className="modal-card-body">
                <div className="field">
                  <div className="control has-icons-left">
                    <div
                      className={`icon is-left has-text-white ${
                        errors.amount && touched.amount
                          ? 'has-background-danger'
                          : 'has-background-success'
                      }`}
                      style={{ width: 40, borderRadius: '10% 0 0 10%' }}
                    >
                      $
                    </div>
                    <Field
                      id="amount"
                      name="amount"
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      className="input pl-6"
                    />
                  </div>

                  <div className="control mt-3">
                    <FieldContainer name="reason" required>
                      <Field
                        id="reason"
                        name="reason"
                        component="textarea"
                        rows="2"
                        required
                        placeholder="Reason"
                        className="textarea"
                      />
                    </FieldContainer>
                  </div>
                  <div className="control mt-3">
                    <FieldContainer name="Business Unit" required>
                      <Field
                        name="businessUnitId"
                        required
                        component={SelectField}
                        query={GET_BUSINESS_UNITS_DROPDOWN}
                        initialMenu
                      />
                    </FieldContainer>
                  </div>
                </div>
                <ServerErrors errorMessages={errorMessages} />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  className="button is-dark"
                  type="button"
                  onClick={() => {
                    resetAndCloseModal(resetForm);
                  }}
                >
                  Close
                </button>
                {action === 'Edit' && (
                  <button
                    className="button is-danger is-outlined"
                    type="button"
                    onClick={() => destroyDebit(debit, refetch)}
                  >
                    Delete
                  </button>
                )}
                <button className="button is-primary" type="submit">
                  {action === 'New' ? 'Create' : 'Update'}
                </button>
              </footer>
            </div>
          </div>
        </form>
      )}
    </Formik>
  ) : null;
}

BillingChargeFormModal.propTypes = {
  customer: PropTypes.object,
  type: PropTypes.string,
  refetch: PropTypes.func.isRequired,
  billingType: PropTypes.string.isRequired,
  errorMessages: PropTypes.array,
  setErrorMessages: PropTypes.func,
  clearErrorMessages: PropTypes.func,
};

export default flowRight(withFormWrap({ initialValues, schema }))(
  BillingChargeFormModal,
);
