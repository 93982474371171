import { UPDATE_CREDIT } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  refetch,
  closeModal,
  setErrorMessages,
}) {
  const result = await client.mutate({
    mutation: UPDATE_CREDIT,
    variables,
  });

  const { updateCredit } = result.data;
  if (updateCredit.errorMessages.length > 0) {
    setErrorMessages(updateCredit.errorMessages);
  } else {
    refetch();
    closeModal();
  }
}

export default update;
